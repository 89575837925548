import { Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@emotion/react";
import { RouteList } from "src/route";

import { Notification } from "../NotificationAlertsModule/components";
import { useProfileStore } from "../ProfileModule/store";

import "./assets/App.scss";
import { appTheme } from "./appTheme";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";

export function App() {
  const isAuth = useProfileStore((store) => store.isAuth);
  return (
    <Router>
      <ThemeProvider theme={appTheme}>
        <div className="wrapper">
          {isAuth && <Header />}
          <main className="content">
            <Suspense>
              <RouteList />
            </Suspense>
          </main>
          {isAuth && <Footer />}
          <Notification />
        </div>
      </ThemeProvider>
    </Router>
  );
}
