import BookmarkIcon from "@mui/icons-material/Bookmark";
import HomeIcon from "@mui/icons-material/Home";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SearchIcon from "@mui/icons-material/Search";
import HistoryIcon from "@mui/icons-material/History";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";

import { MenuItem } from "../interfaces";

export const menuList: MenuItem[] = [
  { name: "search", path: "/", text: "Главная", icon: <HomeIcon /> },
  {
    name: "profile",
    path: "/u/profile",
    text: "Мой профиль",
    icon: <AccountCircleIcon />,
  },
  { name: "search", path: "/search", text: "Поиск", icon: <SearchIcon /> },
  {
    name: "history",
    path: "/u/history",
    text: "Журнал",
    icon: <HistoryIcon />,
  },
  {
    name: "favorites",
    path: "/u/favorites",
    text: "Отслеживаемые",
    icon: <BookmarkIcon />,
  },
  {
    name: "notifications",
    path: "/u/notifications",
    text: "Уведомления",
    icon: <NotificationsIcon />,
  },
  {
    name: "read.users",
    path: "/users",
    text: "Все пользователи",
    icon: <PeopleAltIcon />,
  },
];
