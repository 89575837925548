import React from "react";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";

import { HeaderBurgerMenuListItemProps } from "../../interfaces";
import { useHeaderStore } from "../../store";

const ListItemStyles = {
  color: "black",
  "&:hover": {
    color: "green",
  },
};

export const HeaderBurgerMenuListItem = (
  props: HeaderBurgerMenuListItemProps,
) => {
  const { menuItem } = props;
  const { path, text, icon } = menuItem;

  const setIsHeaderBurgerOpen = useHeaderStore(
    (state) => state.setIsHeaderBurgerOpen,
  );

  const handleItemClick = () => setIsHeaderBurgerOpen(false);

  return (
    <React.Fragment>
      <Link
        onClick={handleItemClick}
        to={path}
        style={{ textDecoration: "none" }}
      >
        <ListItem sx={ListItemStyles}>
          <IconButton color="primary" size="medium" sx={{ mr: 0.5 }}>
            {icon}
          </IconButton>
          {text}
        </ListItem>
      </Link>
      <Divider />
    </React.Fragment>
  );
};
