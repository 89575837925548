import React from "react";
import { Route, Routes } from "react-router-dom";
import { useCheckAccess } from "src/hooks/useCheckAccess";
import { useProfileStore } from "src/modules/ProfileModule/store";

const ProtectedRoute = React.lazy(() => import("./ProtectedRoute"));
const Users = React.lazy(() => import("../pages/Users"));
const Login = React.lazy(() => import("../pages/Login"));
const Search = React.lazy(() => import("../pages/Search"));
const History = React.lazy(() => import("../pages/History"));
const Profile = React.lazy(() => import("../pages/Profile"));
const Favorites = React.lazy(() => import("../pages/Favorites"));
const UserProfile = React.lazy(() => import("../pages/UserProfile"));
const Notifications = React.lazy(() => import("../pages/Notifications"));
const PassengerInfo = React.lazy(() => import("../pages/PassengerInfo"));
const Unauthorized = React.lazy(() => import("../pages/System/Unauthorized"));
const AccessDenied = React.lazy(() => import("../pages/System/AccessDenied"));
const PageNotFound = React.lazy(() => import("../pages/System/PageNotFound"));

export const RouteList = () => {
  const isAuth = useProfileStore((state) => state.isAuth);

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route
        path={"/u/history"}
        element={
          <ProtectedRoute isAuth={isAuth}>
            <History />
          </ProtectedRoute>
        }
      />
      <Route
        path={"/search"}
        element={
          <ProtectedRoute isAuth={isAuth}>
            <Search />
          </ProtectedRoute>
        }
      />
      <Route
        path={`/u/profile`}
        element={
          <ProtectedRoute isAuth={isAuth}>
            <Profile />
          </ProtectedRoute>
        }
      />
      <Route
        path={`/u/favorites`}
        element={
          <ProtectedRoute isAuth={isAuth}>
            <Favorites />
          </ProtectedRoute>
        }
      />
      <Route
        path={`/u/notifications`}
        element={
          <ProtectedRoute isAuth={isAuth}>
            <Notifications />
          </ProtectedRoute>
        }
      />
      <Route
        path={`/users`}
        element={
          <ProtectedRoute
            isAuth={isAuth}
            hasAccess={useCheckAccess("read.users")}
          >
            <Users />
          </ProtectedRoute>
        }
      />
      <Route
        path={`/users/:id`}
        element={
          <ProtectedRoute
            isAuth={isAuth}
            hasAccess={useCheckAccess("read.users")}
          >
            <UserProfile />
          </ProtectedRoute>
        }
      />
      <Route
        path={`/passengers/:id`}
        element={
          <ProtectedRoute isAuth={isAuth}>
            <PassengerInfo />
          </ProtectedRoute>
        }
      />
      <Route
        path={"/"}
        element={
          <ProtectedRoute isAuth={isAuth}>
            {isAuth ? <Search /> : <Login />}
          </ProtectedRoute>
        }
      />
      <Route path="/no-auth" element={<Unauthorized />} />
      <Route path="/access-denied" element={<AccessDenied />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};
