import { createTheme } from "@mui/material/styles";
import { ruRU } from "@mui/material/locale";
import { green, grey } from "@mui/material/colors";

export let appTheme = createTheme({});
appTheme = createTheme(
  {
    typography: {
      fontFamily: `"Jost", "Helvetica", "Arial", sans-serif`,
    },
    palette: {
      primary: {
        main: green[700],
        contrastText: "hsla(0, 0%, 98%, 1)",
      },
      secondary: {
        main: grey[900],
      },
    },
  },
  ruRU,
);
