import List from "@mui/material/List";
import { useProfileStore } from "src/modules/ProfileModule/store";

import { HeaderBurgerMenuListItem } from "../HeaderBurgerMenuListItem";
import { menuList } from "../../constants";

export const HeaderBurgerMenuList = () => {
  const userPermissisons = useProfileStore((store) => store.userPermissions);
  const filteredMenuList = menuList.filter((menuItem) =>
    userPermissisons.includes(menuItem.name),
  );

  return (
    <List data-testid="burger-menu-list">
      {(userPermissisons.includes("full") ? menuList : filteredMenuList).map(
        (menuItem, i) => (
          <HeaderBurgerMenuListItem key={i} menuItem={menuItem} />
        ),
      )}
    </List>
  );
};
