import { create } from "zustand";

import { ProfileStore } from "../interfaces";

const fetchUserProfileFromLocalStorage = () => {
  const storedUserProfileString = localStorage.getItem("userProfile");
  if (storedUserProfileString) {
    return JSON.parse(storedUserProfileString);
  }

  return {
    name: "",
    surname: "",
    patronymic: "",
    email: "",
    phone: "",
    telegram: "",
    role: "",
  };
};

const fetchUserPermissionsFromLocalStorage = () => {
  const storedUserPermissionsString = localStorage.getItem("userPermissions");
  const basicPermissions = [
    "search",
    "profile",
    "history",
    "favorites",
    "notifications",
  ];
  return storedUserPermissionsString
    ? [...basicPermissions, ...JSON.parse(storedUserPermissionsString)]
    : basicPermissions;
};

export const useProfileStore = create<ProfileStore>((set) => ({
  isAuth: Boolean(localStorage.getItem("userProfile")),
  userProfile: fetchUserProfileFromLocalStorage(),
  userPermissions: fetchUserPermissionsFromLocalStorage(),
  searchHistory: "",
  setUserProfile: (v) => set({ userProfile: v }),
  setUserPermissions: (v) =>
    set((state) => ({
      userPermissions: [...state.userPermissions, ...v],
    })),
  setIsAuth: (v) => set({ isAuth: v }),
}));
