import React from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

import { useNotificationAlertsStore } from "../../store";

export const Notification: React.FC = () => {
  const notification = useNotificationAlertsStore(
    (state) => state.notification,
  );
  const clear = useNotificationAlertsStore((state) => state.clear);

  const handleClose = () => {
    clear();
  };

  if (notification) {
    return (
      <Snackbar
        open={!!notification}
        autoHideDuration={6000}
        onClose={handleClose}
        data-testid="notification-snackbar"
      >
        <Alert
          onClose={handleClose}
          severity={notification.type}
          elevation={6}
          variant="filled"
          data-testid="notification-alert"
        >
          {notification.message}
        </Alert>
      </Snackbar>
    );
  } else {
    return <></>;
  }
};
