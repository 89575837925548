import React from "react";
import ReactDOM from "react-dom/client";

import { ApolloProvider } from "@apollo/client";

import client from "./apollo/client";
import reportWebVitals from "./reportWebVitals";
import App from "./modules/AppModule";
import "./assets/global.scss";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
