import { LOGOUT_REQUEST } from "src/api/LogoutApi";
import client from "src/apollo/client";

export const LogoutMethod = () => {
  localStorage.removeItem("userProfile");
  localStorage.removeItem("userPermissions");

  client
    .mutate({ mutation: LOGOUT_REQUEST })
    .then((response) => {
      return response.data.logout;
    })
    .catch((error) => console.log("[LOGOUT]: ", error));
};
