import Drawer from "@mui/material/Drawer";

import { useHeaderStore } from "../../store";
import { HeaderBurgerMenuList } from "../HeaderBurgerMenuList";

export const HeaderBurgerMenu = () => {
  const { isHeaderBurgerOpen, setIsHeaderBurgerOpen } = useHeaderStore(
    (state) => state,
  );
  return (
    <Drawer
      anchor="left"
      open={isHeaderBurgerOpen}
      onClose={() => setIsHeaderBurgerOpen(false)}
    >
      <HeaderBurgerMenuList />
    </Drawer>
  );
};
