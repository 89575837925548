import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { GraphQLError } from "graphql/error/GraphQLError";

interface ExtendedGraphQLError extends GraphQLError {
  code?: string;
}

const httpLink = new HttpLink({
  credentials: "include",
  uri: process.env.REACT_APP_BACKEND_URI,
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((error) => {
      const extendedError = error as ExtendedGraphQLError;
      const errorCode = extendedError.code;

      switch (errorCode) {
        case "UNAUTHENTICATED":
          localStorage.removeItem("userProfile");
          localStorage.removeItem("userPermissions");
          setTimeout(
            () => (window.location.href = "/login?unauthenticated=true"),
            3000,
          );
          break;
        default:
          break;
      }
    });
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

const client = new ApolloClient({
  // link: authLink.concat(httpLink),
  link: ApolloLink.from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache(),
});

export default client;
