import { Container } from "@mui/material";
import React from "react";
import { Copyright } from "src/components/Copyright";

export const Footer = () => {
  return (
    <footer data-testid="main-footer">
      <Container>
        <Copyright sx={{ mt: 2, mb: 2 }} />
      </Container>
    </footer>
  );
};
